
import Home from './Home';
import UploadListing from './components/UploadListing';
import ViewProperties from './components/ViewProperties';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import ExploreProperties from './components/ExploreProperties';
import PanoramaViewer from './components/vr/PanoramaViewer';
import Profile from './components/Profile';
import EditListing from './components/EditListing';
import AboutUs from './components/AboutUs';
import { useAuth0 } from '@auth0/auth0-react';
function App() {

return (
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/ViewProperties" element={<ViewProperties />} />
      <Route path="/UploadListing" element={<UploadListing />} />
      <Route path="/ExploreProperties" element={<ExploreProperties />} />
      <Route path="/VirtualTour" element={<PanoramaViewer/>}/>
      <Route path="/viewproperties/:id" element={<ViewProperties/>}/>
      <Route path="/profile" element={<Profile />} />
      <Route path="/edit-listing/:id" element={<EditListing/>} />
      <Route path="/viewproperties/:id" element={<ViewProperties />} />
      <Route path="/about" element={<AboutUs />} />
    </Routes>
  </BrowserRouter>
);
}


export default App;