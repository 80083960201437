import React, { useState, useEffect, useRef } from 'react';
import { DragControls } from 'three/examples/jsm/controls/DragControls';
import * as PANOLENS from 'panolens';
import './panoramaViewer.css';
import { useNavigate } from 'react-router-dom';
import { Widget } from "@uploadcare/react-widget";
import Footer from '../Footer';
import NavBar from '../NavBar';


function PanoramaViewer() {
  const navigate = useNavigate();
  const [images, setImages] = useState([]);
  const [isEditable, setIsEditable] = useState(true);
  const viewerRef = useRef(null);
  const dragControls = useRef([]);
  const [tourData, setTourData] = useState(images.map(image => ({
    imageUrl: image,
    hotspots: [
      {
        position: { x: 0, y: 0, z: 0 },
        linkedImage: images[(images.indexOf(image) + 1) % images.length]
      },
      {
        position: { x: 0, y: 0, z: 0 },
        linkedImage: images[(images.indexOf(image) - 1 + images.length) % images.length]
      }
    ]
  })));

const handleImageUpload = (info) => {
  console.log(info);
  const groupSize = info.count;
  const baseCdnUrl = info.cdnUrl;

  if (!baseCdnUrl) {
    console.error("CDN URL not found for the uploaded images");
    return;
  }

  if (images.length + groupSize > 7) {
    console.error("Only 7 images per listing can be uploaded");
    return;
  }

  const cdnUrls = Array.from({length: groupSize}, (_, i) => `${baseCdnUrl}nth/${i}/-/format/auto/-/quality/smart/-/progressive/yes/`);
  setImages(prevImages => [...prevImages, ...cdnUrls]);
};
 
  const handleSave = () => {
    if (images.length === 0) {
      alert('No images to save. Please add some images to the tour.');
      return;
    }
    setIsEditable(false);
    dragControls.current.forEach(dc => dc.dispose()); // Dispose all DragControls
  
    // Get the scene and filter its children to get the panoramas
    const panoramas = viewerRef.current.getScene().children.filter(child => child instanceof PANOLENS.ImagePanorama);
  
    // Construct new tour data
    const newTourData = images.map((image, index) => {
      const panorama = panoramas[index];
      const hotspots = panorama.children.filter(child => child instanceof PANOLENS.Infospot);
      return {
        imageUrl: image,
        hotspots: hotspots.map(hotspot => ({
          position: { x: hotspot.position.x, y: hotspot.position.y, z: hotspot.position.z },
          linkedImage: images[(index + (hotspot === hotspots[0] ? 1 : -1) + images.length) % images.length] // Get the index of the next or previous image
        }))
      };
    });
  
    // Update tour data state
    setTourData(newTourData);
    console.log(newTourData); // Log the new tour data


    navigate('/UploadListing', { state: { tourData: newTourData } }); // Pass tourData to UploadListing
  };

  


  useEffect(() => {
    if (images.length > 0 && viewerRef.current && isEditable) {
      const viewer = new PANOLENS.Viewer({
        container: viewerRef.current,
        autoRotate: true,
        autoRotateSpeed: 0.3,
        controlBar: true,
      });
  
      const panoramas = images.map(image => new PANOLENS.ImagePanorama(image));
      panoramas.forEach((panorama, index) => {
        viewer.add(panorama);
        if (index === 0) {
          viewer.setPanorama(panorama);
        }
  
        // Create navigation hotspots
        const nextHotspot = new PANOLENS.Infospot(300, PANOLENS.DataImage.ArrowRight);
        nextHotspot.position.set(5000, 0, 0);
        nextHotspot.addEventListener('click', () => {
          viewer.setPanorama(panoramas[(index + 1) % panoramas.length]);
        });
  
        const prevHotspot = new PANOLENS.Infospot(300, PANOLENS.DataImage.ArrowLeft);
        prevHotspot.position.set(-5000, 0, 0);
        prevHotspot.addEventListener('click', () => {
          viewer.setPanorama(panoramas[(index - 1 + panoramas.length) % panoramas.length]);
        });
  
        // Add the hotspots to the panorama
        panorama.add(nextHotspot);
        panorama.add(prevHotspot);
  
        // Add drag controls to the hotspots
        const hotspots = [nextHotspot, prevHotspot];
        const dragControl = new DragControls(hotspots, viewer.getCamera(), viewer.renderer.domElement);
        dragControls.current.push(dragControl); // Add to the array
        dragControl.addEventListener('dragstart', () => {
          viewer.getControl().enabled = false;
        });
  
        dragControl.addEventListener('dragend', () => {
          viewer.getControl().enabled = true;
        });
      });
      // Update the viewerRef with the initialized viewer
      viewerRef.current = viewer;
    }
  }, [images, isEditable]);
  const filesizelimit=()=>{
    return (fileInfo) => {
      if (fileInfo.size > 10000000) {
        alert('Max file size is 10MB');
        return false;
      }
      return true;
    };
  }
  const validators =[filesizelimit()];

  return (
    <>
    <NavBar/>
    <div className="tour">
      <div className="container">
        <div className="tour__head">
        <h2 className='text-xl font-semibold'>Build your Virtual Tour in just a few simple steps!</h2>

          <h3 className='mt-2 text-lg font-medium'>Step 1: Upload 360 degree images of your property</h3>
          <div className='flex justify-center items-center gap-4 mb-5 mt-5 '>
          <Widget 
          publicKey="e654302b7d7dad64025e"
          clearable
          multiple
          onChange={handleImageUpload}
          validators={validators}
          />
         </div>
         <h3 className='mt-2 text-lg font-medium'>Step 2: Drag the navigation hotspots to link the images</h3>
        </div>
        <div className="image-container" ref={viewerRef} />
        <div className='flex flex-col items-center justify-center'>
          <h3 className='mt-2 text-lg font-medium'>Step 3: Review your tour and save it</h3>
          <div className='flex space-x-2 mb-4'>
            <button onClick={() => window.location.reload()} className="w-32 h-10 bg-white text-purple-600 border border-purple-600 p-2 rounded-md hover:bg-purple-600 hover:text-white transition duration-200 mt-2">Rebuild Tour</button>
            <button onClick={handleSave} disabled={!isEditable} className='w-32 h-10 bg-purple-500 text-white p-2 rounded-md border hover:bg-white hover:text-purple-600 hover:border-purple-600 transition duration-200 mt-2'>Save</button>
          </div>
        </div>
      </div>
    </div>
    <div className='mt-8'>
    <Footer/>
    </div>
    
    </>
  );    
}

export default PanoramaViewer;
