import React from 'react'
import NavBar from './components/NavBar'
import Carousel from './components/Carousel'
import Footer from './components/Footer'
import './home.css'
import Hero from './components/Hero'
import Services from './components/Services'



const Home = () => {
  return (
    <>  
    <NavBar />
    <Hero />
    <Carousel/>
    <Services/>
    <Footer />
    </>
    
  )
}


export default Home;
