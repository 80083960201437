import {useAuth0} from '@auth0/auth0-react';

const SignupButton = () => {
    const { loginWithRedirect  , isAuthenticated} = useAuth0();

    return(
        !isAuthenticated && (
            <button onClick={()=> loginWithRedirect()} className='bg-purple-600 ml-5 text-white px-4 py-2 rounded-md shadow-md'>
                Signup
            </button>
        )
    )
}
export default SignupButton