'use client';
import React , { useState, useEffect } from 'react'
import { useAuth0 } from "@auth0/auth0-react"
import {Link} from "react-router-dom";
import NavBar from './NavBar';
import Footer from './Footer';
import { PiSmileySadDuotone } from "react-icons/pi";
import img1 from './images/frames-for-your-heart-2d4lAQAlbDA-unsplash.jpeg';
import { Spinner } from 'flowbite-react';


const Profile = () => {

  const { user, isAuthenticated, isLoading } = useAuth0();
  const [listings, setListings] = useState([]);
  const [Loading, setLoading] = useState(true);

  useEffect(() => {
    if(isAuthenticated){
      //making api request to fetch user listings
      fetchListings();
    }
  }, [isAuthenticated]);

  const fetchListings = async () => {  
    try{
      const response = await fetch(`https://propvrty.onrender.com/api/listings/user/${user.sub}`);
      const data = await response.json();
      setLoading(false);
      console.log(data);
      setListings(data);
    } catch(error){
      console.error('Error fetching listings', error);
    }
  }

 
  const deleteListing = async (id) => {
    try {
      const response = await fetch(`https://propvrty.onrender.com/api/listings/${id}`, {
        method: 'DELETE',
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      } else {
        // Fetch the listings again after the delete operation
        const response = await fetch(`https://propvrty.onrender.com/api/listings/user/${user.sub}`);
        const data = await response.json();
        setListings(data);
      }
    } catch (error) {
      console.error('Error deleting listing', error);
    }
  }
  const editListing = async (id, updatedData) => {
    try {
      const response = await fetch(`https://propvrty.onrender.com/api/listings/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updatedData),
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      } else {
        // Fetch the listings again after the edit operation
        fetchListings();
      }
    } catch (error) {
      console.error('Error editing listing', error);
    }
  }
  return (
    <>
      {/* isAuthenticated && ( */}
      
      {/* ) */}
      <NavBar/>

      {Loading ? (
        <div className="flex items-center justify-center h-screen">
        <Spinner color="purple" size="xl" />
      </div>
      ):(
        <div>
           <div>
        <img src={img1} alt="profile background" className="w-full h-80 object-cover"/>
      </div>
      <div className='p-4 h-screen flex flex-col items-center justify-center'>
        {/* user profile details */}
        <div className='mt-12 items-center justify-center'>
          <h2 className='text-2xl font-semibold text-gray-700'>My listings</h2>
          {listings.length === 0 ? (
            <div>
            <div className='flex flex-col items-center justify-center text-2xl bg-white shadow-lg p-6 rounded-lg'>
              <p><PiSmileySadDuotone className='text-4xl'/>You do not have any listings.</p>
            </div>
            </div>
            
          ) : (
            // Display the listings here
            <div className=/*'mt-10 bg-white mb-10 w-3/4 m-auto'*/ 'w-full'>
            <div className=/*"grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4 gap-4"*/" w-full grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-4 gap-4">
              {listings.map((property, index) => (
                      <Link to={`/viewproperties/${property._id}`} key={index} className="max-w-sm w-screen py-6 px-3">
                      <div className="flex items-center justify-center">
                        <div className="max-w-sm w-full sm:w-full lg:w-full py-6 px-3">
                          <div className="bg-white shadow-xl rounded-lg overflow-hidden">
                            <div
                              className="bg-cover bg-center h-56 p-4"
                              style={{
                                backgroundImage: `url(${property.imageURLs[0]})`,
                              }}
                            >
                              <div className="flex justify-end">
                              <svg
                          className="h-6 w-6 text-white fill-current" //add hover and onclick
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                        >
                          <path d="M12.76 3.76a6 6 0 0 1 8.48 8.48l-8.53 8.54a1 1 0 0 1-1.42 0l-8.53-8.54a6 6 0 0 1 8.48-8.48l.76.75.76-.75zm7.07 7.07a4 4 0 1 0-5.66-5.66l-1.46 1.47a1 1 0 0 1-1.42 0L9.83 5.17a4 4 0 1 0-5.66 5.66L12 18.66l7.83-7.83z" />
                        </svg>
                              </div>
                            </div>
                            <div className="p-4">
                              <p className="uppercase tracking-wide text-sm font-bold text-gray-700">
                                {property.location}
                              </p>
                              <p className="text-3xl text-gray-900">${property.price}</p>
                              <p className="text-gray-700">{property.type}</p>
                            </div>
                            <div className="flex p-4 border-t border-gray-300 text-gray-700">
                              <div className="flex-1 inline-flex items-center">
                              <svg
                              className="h-6 w-6 text-gray-600 fill-current mr-3"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                            >
                              <path d="M0 16L3 5V1a1 1 0 0 1 1-1h16a1 1 0 0 1 1 1v4l3 11v5a1 1 0 0 1-1 1v2h-1v-2H2v2H1v-2a1 1 0 0 1-1-1v-5zM19 5h1V1H4v4h1V4a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v1h2V4a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v1zm0 1v2a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1V6h-2v2a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1V6H3.76L1.04 16h21.92L20.24 6H19zM1 17v4h22v-4H1zM6 4v4h4V4H6zm8 0v4h4V4h-4z" />
                            </svg>
                                <p>
                                  <span className="text-gray-900 font-bold">
                                    {property.bedrooms}
                                  </span>{' '}
                                  Rooms
                                </p>
                              </div>
                              <div className="flex-1 inline-flex items-center">
                                <svg
                              className="h-6 w-6 text-gray-600 fill-current mr-3"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                            >
                              <path
                                fillRule="evenodd"
                                d="M17.03 21H7.97a4 4 0 0 1-1.3-.22l-1.22 2.44-.9-.44 1.22-2.44a4 4 0 0 1-1.38-1.55L.5 11h7.56a4 4 0 0 1 1.78.42l2.32 1.16a4 4 0 0 0 1.78.42h9.56l-2.9 5.79a4 4 0 0 1-1.37 1.55l1.22 2.44-.9.44-1.22-2.44a4 4 0 0 1-1.3.22zM21 11h2.5a.5.5 0 1 1 0 1h-9.06a4.5 4.5 0 0 1-2-.48l-2.32-1.15A3.5 3.5 0 0 0 8.56 10H.5a.5.5 0 0 1 0-1h8.06c.7 0 1.38.16 2 .48l2.32 1.15a3.5 3.5 0 0 0 1.56.37H20V2a1 1 0 0 0-1.74-.67c.64.97.53 2.29-.32 3.14l-.35.36-3.54-3.54.35-.35a2.5 2.5 0 0 1 3.15-.32A2 2 0 0 1 21 2v9zm-5.48-9.65l2 2a1.5 1.5 0 0 0-2-2zm-10.23 17A3 3 0 0 0 7.97 20h9.06a3 3 0 0 0 2.68-1.66L21.88 14h-7.94a5 5 0 0 1-2.23-.53L9.4 12.32A3 3 0 0 0 8.06 12H2.12l3.17 6.34z"
                              />
                            </svg>
                                <p>
                                  <span className="text-gray-900 font-bold">
                                    {property.bathrooms}
                                  </span>{' '}
                                  Baths
                                </p>
                              </div>
                            </div>
                            <div className="px-4 pt-3 pb-4 border-t border-gray-300 bg-gray-100">
                              <div className="text-xs uppercase font-bold text-gray-600 tracking-wide">
                                Realtor
                              </div>
                              <div className="flex items-center pt-2">
                                <div
                                  className="bg-cover bg-center w-10 h-10 rounded-full mr-3"
                                  style={{
                                    backgroundImage: `url(${property.userprofilepicture})`,
                                  }}
                                ></div>
                                <div>
                                  <p className="font-bold text-gray-900">
                                    {property.username}
                                  </p>
                                  <p className="text-sm text-gray-700">
                                    {property.phoneNumber}
                                  </p>
                                </div>
                              </div>
                              <div>
                                <Link to={`/edit-listing/${property._id}`} className='bg-gray-600 hover:bg-purple-500  border hover:border-gray-600 text-white p-3 w-20 rounded-lg mr-3'>Edit</Link>
                                <button onClick={(event) => {
                                  event.preventDefault();
                                  if (window.confirm("Are you sure you want to delete this listing?")) {
                                    deleteListing(property._id);

                                    window.alert("Listing successfully deleted.");
                                  }
                                 
                                }} class="bg-white  border border-gray-600 hover:bg-red-600 hover:text-white text-gray-600 p-2 rounded-lg mt-3">
                                  Delete
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
              ))}
            </div>
            
          </div>
          )}
        </div>
        </div>
       
        </div>
      )}
      <div>
      <Footer/>
      </div>
   
    </>
  )
}

export default Profile