import React from 'react'
import { useEffect, useState} from 'react';
import axios from 'axios';
import { useParams} from 'react-router-dom';
import {Carousel} from 'flowbite-react';
import NavBar from './NavBar'
import Carousel2 from './Carousel';
import Vtour from './Vtour';
import { LuBath, LuParkingCircle } from "react-icons/lu";
import { IoBedOutline } from "react-icons/io5";
import { FaLocationDot } from "react-icons/fa6";
import Footer from './Footer';
import { Spinner } from 'flowbite-react';




const ViewProperties = () => {
 
  const  [showTour, setShowTour] = useState(false);
  const {id} = useParams();
  const [listing, setListing] = useState({});
 
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchListing = async () => {
      try {
        const response = await axios.get(`https://propvrty.onrender.com/api/listings/${id}`);
        setListing(response.data);
        console.log(response.data);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching data: ', error);
      }
    };

    fetchListing();
  }, [id]);


  return (
    <div>
      <NavBar/>
      {isLoading ? (
       <div className="flex items-center justify-center h-screen">
          <Spinner color="purple" size="xl" />
        </div>
        ):(
          <div className='container mt-16 mx-auto pt-6 flex flex-col md:flex-row'>
        <div className='w-full  md:w-1/2 mb-6'> 
            <div className='h-86 sm:h-64 xl:h-80 2xl:h-96+ mb-4  h-[500px] mb-4'>
                <Carousel>
                  {listing.imageURLs.map((url, index) => (
                    <img key={index} src={url} alt={`Slide ${index}`} className='w-full h-full object-cover'/>
                  ))}
                </Carousel>
            </div>
          </div>
        
        {/* insert next code here */}
        {/* display listing data */}
        <div className='w-full md:w-1/2 pl-9 mx-auto'>
          <h1 className='text-md font-bold mb-4'>{listing.title}</h1>
          <p className='text-lg font-bold mb-4'>${listing.price}</p>
          <p className='text-gray-700 mb-2'>{listing.description}</p>
          <div className='flex flex-col gap-4 sm:flex-row sm:gap-10 border-t border-b border-gray-300'>
            <div>
              <p className='text-gray-500 text-sm'>BEDROOMS</p>
              <p className='text-gray-700 mb-2 flex items-center font-semibold gap-1'><IoBedOutline /> {listing.bedrooms}</p>
            </div>
            <div>
             <p className='text-gray-500 text-sm'>BATHROOMS</p>
             <p className='text-gray-700 mb-2 flex items-center font-semibold gap-1'><LuBath/> {listing.bathrooms}</p>
            </div>
            <div>
             <p className='text-gray-500 text-sm'>PARKING</p>
             <p className='text-gray-700 mb-2 flex items-center font-semibold gap-1'><LuParkingCircle/> {listing.parkingSlots}</p>
            </div>
            <div>
             <p className='text-gray-500 text-sm'>PROPERTY TYPE</p>
             <p className='text-gray-700 font-medium mb-4'>{listing.type}</p>
            </div>
          </div>
          <p className='mt-1 mb-2 font-medium flex items-center gap-2 text-md'><FaLocationDot />{listing.location}</p>
          <p className='text-gray-700 mb-2'>{listing.phoneNumber}</p>
          <p className='text-gray-700 mb-4'>Seller: {listing.username}</p>
          <div className='flex flex-col space-y-4'>
          <div className='flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4'>
        <button onClick={()=>setShowTour(!showTour)} class="bg-purple-600 hover:bg-white hover:border-purple-600 text-white hover:text-purple-600 py-2 px-4 rounded-full max-w-xs w-full text-center border border-transparent hover:border-purple-600">
          View VR Tour
        </button>
      </div>
        </div>
        </div>
      </div>
        )}
      
      <div className=''>
        {showTour && <Vtour tourData={listing.tourData} />}
      </div>
      <div className='container mt-16 mx-auto pt-6 text-xl font-bold text-center md:text-left'>
        <h2>Recommended properties</h2>
      </div>
      <Carousel2/>
      <Footer/>
    </div>
  )
}

export default ViewProperties;