import React, { useRef, useEffect } from 'react';
import * as PANOLENS from 'panolens';
import './vr/panoramaViewer.css';

const Vtour = ({ tourData }) => {
  const viewerRef = useRef(null);

  useEffect(() => {
    if (tourData.length > 0 && viewerRef.current) {
      const viewer = new PANOLENS.Viewer({
        container: viewerRef.current,
        autoRotate: true,
        autoRotateSpeed: 0.3,
        controlBar: true,
      });

      // Create panoramas
      const panoramas = tourData.map(tourItem => new PANOLENS.ImagePanorama(tourItem.imageUrl));


      // Set up hotspots for each panorama
      panoramas.forEach((panorama, index) => {
        const tourItem = tourData[index];
        tourItem.hotspots.forEach(hotspot => {
          const linkedImageIndex = tourData.findIndex(item => item.imageUrl === hotspot.linkedImage);
          const linkedPanorama = panoramas[linkedImageIndex];
          const infospot = new PANOLENS.Infospot(hotspot.radius, hotspot.image);
          infospot.position.set(hotspot.position.x, hotspot.position.y, hotspot.position.z);
          infospot.addEventListener('click', function () {
            viewer.setPanorama(linkedPanorama);
          });
          panorama.add(infospot);
        });
      });

      // Add panoramas to the viewer
      panoramas.forEach((panorama, index) => {
        viewer.add(panorama);
        if (index === 0) {
          viewer.setPanorama(panorama);
        }
      });
    }
  }, [tourData]);

  return (
    <div className="tour">
      <div className="container">
        <div className="tour__head">
          <h2>Virtual Tour</h2>
        </div>
        <div className="image-container" ref={viewerRef} />
      </div>
    </div>
  )};

export default Vtour;