import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import SignupButton from './SignupButton';
import LogOutButton from './LogOutButton';
import {useNavigate} from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

const Nav = () => {
    
  
  const {user, loginwithRedirect, isAuthenticated, logout} = useAuth0();
  const location = useLocation();

  const navigate = useNavigate();
  const gotohomepage =() => {
    navigate('/');
  };

  let Links = [
    { name: "Home", link: "/" }, // Assuming "/" is your home route
    { name: "About Us", link: "/about" },
    { name: "List Properties", link: "/UploadListing" },
    { name: "Explore Properties", link: "/ExploreProperties" },
  ];

  let [open, setOpen] = useState(false);

 
  return (
    <div className='shadow-md w-full fixed top-0 left-0 z-50'>
      <div className='md:flex items-center justify-between bg-white py-4 md:px-10 px-7'>
        <div className='font-bold text-2xl cursor-pointer flex items-center font-[Poppins] text-black-800'>
          <span onClick={gotohomepage} className='text-3xl text-indigo-600 mr-1 pt-2'>
            <ion-icon name="home-outline"></ion-icon>
          </span>
          <div onClick={gotohomepage}>PropVRty</div>
        </div>

        <div onClick={() => setOpen(!open)} className='text-3xl absolute right-8 top-6 cursor-pointer md:hidden'>
          <ion-icon name={open ? 'close' : 'menu'}></ion-icon>
        </div>

        <ul className={`md:flex md:items-center md:pb-0 pb-12 absolute md:static bg-white md:z-auto z-[-1] left-0 w-full md:w-auto md:pl-0 pl-9 transition-all duration-500 ease-in ${open ? 'top-20' : 'top-[-490px]'}`}>
        {Links.map((link) => (
    <li key={link.name} className='md:ml-8 text-xl md:my-0 my-7'>
      <NavLink 
        to={link.link} 
        className={`text-black-800 hover:text-purple-600 duration-500 ${location.pathname === link.link ? 'text-purple-600' : ''}`}
      >
        {link.name}
      </NavLink>
    </li>
  ))}

        {isAuthenticated ? (
          <>
            <li className='flex items-center'>
            <li className='md:ml-8 text-xl md:my-0 my-7'> 
              <LogOutButton/>
            </li>
              <Link to="/profile">
                <img src={user.picture} alt={user.name} className='w-10 h-10 rounded-full ml-6'/>
                {/* <span>{user.name}</span> */}
              </Link>
            </li>
          </>
          
        ): (
          <>
            <SignupButton />
            {/* <LogOutButton /> */}
          </>
        )}

        
      
        
        
        
      </ul>
      </div>
    </div>
  )
}

export default Nav;
