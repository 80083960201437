import {useAuth0} from '@auth0/auth0-react';

const LogOutButton = () => {
    const { isAuthenticated} = useAuth0();
    const {logout} = useAuth0();

    return(
        isAuthenticated && (
            <button onClick={()=> logout()} className='bg-purple-600 ml-5 text-white px-4 py-2 rounded-md shadow-md'>
                Log Out
            </button>
        )
    )
}
export default LogOutButton