import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {Link} from "react-router-dom";
import 'react-lazy-load-image-component/src/effects/blur.css'; // for blur effect
import { Spinner } from 'flowbite-react';
import {BiBuildings, BiMap, BiMoney } from 'react-icons/bi';
import Button from './Button';


function PropertiesList() {
  
  const [properties, setProperties] = useState([]);
  const [displayProperties, setDisplayProperties] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [locationFilter, setLocationFilter] = useState('');
  const [propertyTypeFilter, setPropertyTypeFilter] = useState('');
  const [priceSortOrder, setPriceSortOrder] = useState('');
  const [bedroomsFilter, setBedroomsFilter] = useState('');
  const itemsPerPage = 10;

  const fetchProperties = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get('https://propvrty.onrender.com/api/listings');
      const sortedData = response.data.sort((a, b) => (b._id.toString() > a._id.toString()) ? 1 : -1);
      setProperties(sortedData);
      setDisplayProperties(sortedData.slice(0, itemsPerPage));
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching data: ', error);
    }
  };
  
  useEffect(() => {
    fetchProperties();
  }, []);

 const loadMore = () => {
    setDisplayProperties((prev) => [
      ...prev,
      ...properties.slice(prev.length, prev.length + itemsPerPage),
    ]);
};

useEffect(() => {
  filterProperties();
}, [locationFilter, propertyTypeFilter, priceSortOrder, bedroomsFilter]);

const filterProperties = () => {
  let filtered = [...properties];

  // Location filter
  if (locationFilter) {
    filtered = filtered.filter(property => property.location.toLowerCase().includes(locationFilter.toLowerCase()));
  }

  // Property type filter
  if (propertyTypeFilter) {
    filtered = filtered.filter(property => property.type === propertyTypeFilter);
  }

  // Bedrooms filter
  if (bedroomsFilter) {
    filtered = filtered.filter(property => property.bedrooms === parseInt(bedroomsFilter));
  }

  // Price sorting
  if (priceSortOrder === 'high-to-low') {
    filtered.sort((a, b) => b.price - a.price);
  } else if (priceSortOrder === 'low-to-high') {
    filtered.sort((a, b) => a.price - b.price);
  }

  setDisplayProperties(filtered);
};

const handleSearch = () => {
  filterProperties();
};


  return (
    <>
     {isLoading ? (
      <div className="flex items-center justify-center h-screen">
      <Spinner color="purple" size="xl" />
      </div>
     ) : (

      <div className='mt-20 pt-10'>
        <div className="md:max-w-[80%] w-full mx-auto relative sm:-mt-0">
          <div className="flex flex-col gap-x-4 gap-y-4 md:flex-row card-shadow dark:shadow-none md:ml-5 justify-center items-center bg-gray-200 rounded-lg p-6 shadow-md">
            {/* Location Filter */}
            <div className="flex-1 p-2 rounded-lg dark:bg-hover-color-dark card-bordered w-1/2">
              <div className="flex items-center">
                <BiMap />
                <h1 className="font-medium inline ml-1">Location</h1>
              </div>
                <div className="flex-align-center gap-x-2">
                  <input
                    type="text"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Enter location"
                    value={locationFilter}
                    onChange={e => setLocationFilter(e.target.value)}
                  />
                </div>
            </div>
            
            {/* Property Type Filter */}
            <div className="flex-1 p-2 rounded-lg dark:bg-hover-color-dark card-bordered w-1/2">
            <div className="flex items-center">
              <BiBuildings />
              <h1 className="font-medium ml-1">Property Type</h1>
              </div>
              <div className="flex-align-center gap-x-2">
            
                <select
                  name=""
                  id=""
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:placeholder-gray-800  dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  value={propertyTypeFilter}
              onChange={e => setPropertyTypeFilter(e.target.value)}
                > 
                    <option value="" disabled selected>Select property type</option>
                    <option value="house">House</option>
                    <option value="farm_house">Farm House</option>
                    <option value="land">Land</option>
                    <option value="flat">Flat</option>
                    <option value="villa">Villa</option>
                    <option value="offfice buildings">Offfice Buildings</option>
                    <option value="apartments">Apartments</option>
                    <option value="mansions">Mansions</option>
                    <option value="penthouse">Penthouse</option>
                    <option value="living room">Other</option>
                </select>
              </div>
            </div>
            
            {/* Price Range Filter */}
            <div className="flex-1 p-2 rounded-lg dark:bg-hover-color-dark card-bordered w-1/2">
            <div className="flex items-center">
              <BiMoney />
              <h1 className="font-medium ml-1">Price range</h1>
            </div>  
              <div className="flex-align-center gap-x-2">
             
                <select
                  name=""
                  id=""
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:placeholder-gray-800  dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  value={priceSortOrder}
                  onChange={e => setPriceSortOrder(e.target.value)}
                >
                  <option value="" disabled selected>Select price range</option>
                  <option value="high-to-low ">highest to lowest</option>
                  <option value="low-to-high">lowest to highest</option>
                  {/* ... other options ... */}
                </select>
              </div>
            </div>
            
            {/* Bedrooms Filter */}
            <div className="flex-1 p-2 rounded-lg dark:bg-hover-color-dark card-bordered w-1/2">
              <div className="flex items-center">
                <BiBuildings />
                <h1 className="font-medium ml-1">Rooms</h1>
              </div>  
              <div className="flex-align-center gap-x-2">
                <input
                  type="number"
                  min="1"
                  placeholder="Enter number of rooms"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  value={bedroomsFilter}
              onChange={e => setBedroomsFilter(e.target.value)}
                />
              </div>
            </div>
            <div className="flex-1 rounded-lg dark:bg-hover-color-dark w-1/2 flex items-center justify-center">
            <Button onClick={handleSearch}>Search</Button>
            </div>
          </div>
        </div>
        <div div className='flex justify-center items-center p-5 rounded-md mb-5'>
          <h1 className='text-gray-700 text-2xl font-bold'>Showcase Properties</h1>
       </div>
      
      <div className='mt-10 bg-white mb-10 w-10/12 m-auto '>
        <div style={{display: 'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))', gap: '1rem'}}>
          {displayProperties.map((property, index) => (
            <Link to={`/viewproperties/${property._id}`} key={index} className="max-w-sm w-full py-6 px-3">
              <div className="flex items-center justify-center">
                <div className="max-w-sm w-full sm:w-full lg:w-full py-6 px-3">
                  <div className="bg-white shadow-xl rounded-lg overflow-hidden">
                      <img
                        src={`${property.imageURLs[0]}-/scale_crop/400x300/center/`}
                        alt="Property"
                        loading="lazy"
                        className="object-cover object-center h-56 w-full"
                      />
                    <div className="p-4">
                      <p className="uppercase tracking-wide text-sm font-bold text-gray-700">
                        {property.location}
                      </p>
                      <p className="text-3xl text-gray-900">${property.price}</p>
                      <p className="text-gray-700">{property.type}</p>
                    </div>
                    <div className="flex p-4 border-t border-gray-300 text-gray-700">
                      <div className="flex-1 inline-flex items-center">
                      <svg
                      className="h-6 w-6 text-gray-600 fill-current mr-3"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                    >
                      <path d="M0 16L3 5V1a1 1 0 0 1 1-1h16a1 1 0 0 1 1 1v4l3 11v5a1 1 0 0 1-1 1v2h-1v-2H2v2H1v-2a1 1 0 0 1-1-1v-5zM19 5h1V1H4v4h1V4a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v1h2V4a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v1zm0 1v2a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1V6h-2v2a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1V6H3.76L1.04 16h21.92L20.24 6H19zM1 17v4h22v-4H1zM6 4v4h4V4H6zm8 0v4h4V4h-4z" />
                    </svg>
                        <p>
                          <span className="text-gray-900 font-bold">
                            {property.bedrooms}
                          </span>{' '}
                          Rooms
                        </p>
                      </div>
                      <div className="flex-1 inline-flex items-center">
                         <svg
                      className="h-6 w-6 text-gray-600 fill-current mr-3"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fillRule="evenodd"
                        d="M17.03 21H7.97a4 4 0 0 1-1.3-.22l-1.22 2.44-.9-.44 1.22-2.44a4 4 0 0 1-1.38-1.55L.5 11h7.56a4 4 0 0 1 1.78.42l2.32 1.16a4 4 0 0 0 1.78.42h9.56l-2.9 5.79a4 4 0 0 1-1.37 1.55l1.22 2.44-.9.44-1.22-2.44a4 4 0 0 1-1.3.22zM21 11h2.5a.5.5 0 1 1 0 1h-9.06a4.5 4.5 0 0 1-2-.48l-2.32-1.15A3.5 3.5 0 0 0 8.56 10H.5a.5.5 0 0 1 0-1h8.06c.7 0 1.38.16 2 .48l2.32 1.15a3.5 3.5 0 0 0 1.56.37H20V2a1 1 0 0 0-1.74-.67c.64.97.53 2.29-.32 3.14l-.35.36-3.54-3.54.35-.35a2.5 2.5 0 0 1 3.15-.32A2 2 0 0 1 21 2v9zm-5.48-9.65l2 2a1.5 1.5 0 0 0-2-2zm-10.23 17A3 3 0 0 0 7.97 20h9.06a3 3 0 0 0 2.68-1.66L21.88 14h-7.94a5 5 0 0 1-2.23-.53L9.4 12.32A3 3 0 0 0 8.06 12H2.12l3.17 6.34z"
                      />
                    </svg>
                        <p>
                          <span className="text-gray-900 font-bold">
                            {property.bathrooms}
                          </span>{' '}
                          Baths
                        </p>
                      </div>
                    </div>
                    <div className="px-4 pt-3 pb-4 border-t border-gray-300 bg-gray-100">
                      <div className="text-xs uppercase font-bold text-gray-600 tracking-wide">
                        Realtor
                      </div>
                      <div className="flex items-center pt-2">
                        <div
                          className="bg-cover bg-center w-10 h-10 rounded-full mr-3"
                          style={{
                            backgroundImage: `url(${property.userprofilepicture})`,
                          }}
                        ></div>
                        <div>
                          <p className="font-bold text-gray-900">
                            {property.username}
                          </p>
                          <p className="text-sm text-gray-700">
                            {property.phoneNumber}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          ))}
        </div>
      
            <div className="flex justify-center mt-10">
              {displayProperties.length < properties.length && (
                <button
                onClick={loadMore}
                  className="bg-purple-500 hover:bg-purple-500 hover:bg-opacity-75 text-white font-bold py-2 px-4 rounded"
                >
                  Load More
                </button>
              )}
            </div>
   
      </div>
    </div>
     
     )}
    </>
  );
}

export default PropertiesList;