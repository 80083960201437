import React from 'react'

import  { useState ,useEffect } from "react";
import './UploadListing.css';
import NavBar from './NavBar';
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Confetti from 'react-dom-confetti';
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { Widget } from "@uploadcare/react-widget";
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVrCardboard } from '@fortawesome/free-solid-svg-icons';
import { Spinner } from 'flowbite-react';
import Footer from './Footer';


const UploadListing = () => {

  const navigate = useNavigate();
  const location = useLocation();
  const tourData = location.state?.tourData;
  const [isLoading, setIsLoading] = useState(false);
  
  console.log(tourData);



  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });}

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Submitting logic...
      localStorage.removeItem('formData'); // Clear form data from local storage on successful submission
    } catch (error) {
      console.error('Error creating listing:', error);
    }

    // Validation for location
  if (formData.location.trim() === "") {
    toast.error("Location is required.");
    return;
  }

  // Validation for title
  if (formData.title.trim() === "") {
    toast.error("Title is required.");
    return;
  }

  // Validation for description
  if (formData.description.trim() === "") {
    toast.error("Description is required.");
    return;
  }

  // Validation for other numeric fields
  const numericFields = ["parkingSlots", "price", "bedrooms", "bathrooms"];
  for (const field of numericFields) {
  if (isNaN(formData[field])) {
    // Existing validation for non-numeric values
    toast.error(`Invalid value for ${field}`);
    return;
  } else if (field === 'price' && formData[field] < 0) {
    // New validation for negative price
    toast.error('Price cannot be negative');
    return;
  }
}
  for (const field of numericFields) {
    if (isNaN(formData[field])) {
      toast.error(`${field} must be a valid number.`);
      return;
    }
  }

  // Validation for phone number
  const phoneNumberPattern = /^\d{10}$/;
  if (!phoneNumberPattern.test(formData.phoneNumber)) {
    toast.error("Phone number must be exactly 10 digits.");
    return;
  }



    if (!isAuthenticated) {
  toast.error('You must be signed in to upload a listing.');
  return;
}

// Validation for images
if (formData.imageURLs.length < 1 || formData.imageURLs.length > 7) {
  toast.error('You must upload at least 1 and at most 7 images.');
  return;
}
  
    // If images are being uploaded, don't do anything
    if (uploading) {
      return;
    }
    
    
    setUploading(true);
    setIsLoading(true);
    
    try {
      console.log(user);
      const response = await axios.post('https://propvrty.onrender.com/api/listings', {
        ...formData,
        tourData:tourData, // Include tour data in the object
        userId: user.sub, // Include userId in the object
        username: user.name, // Include username in the object
        useremail: user.email, // Include user email in the object
        userprofilepicture: user.picture // Include user profile picture in the object
      });
      
      if (response.status === 200 || response.status === 201) {
        console.log('Listing created:', response.data);
        setIsLoading(false);
        window.alert('Listing successfully created! 🎉');
        setShowConfetti(true); // Show confetti
        window.location.reload();
      } else {
        console.log('Error creating listing:', response.status, response.statusText);
      }
    } catch (error) {
      console.error('Error creating listing:', error);
    } finally {
      setUploading(false);
    }
  };
  const [showConfetti, setShowConfetti] = useState(false);
  const { user, isAuthenticated } = useAuth0();
  const [formData, setFormData] = useState(() => {
    // Retrieve form data from local storage if available, otherwise set initial state
    const savedFormData = localStorage.getItem('formData');
    return savedFormData ? JSON.parse(savedFormData) : {
      location: "",
      type: "",
      title: "",
      description: "",
      parkingSlots: 0,
      price: 0,
      phoneNumber: "",
      bedrooms: 0,
      bathrooms: 0,
      imageURLs: [],
    };
  });
  const [imageUploadError,setImageUploadError]= useState(false);
  const [uploading, setUploading]= useState(false);
  const [uploadingImages, setUploadingImages] = useState(false);
 

  console.log(formData);

  useEffect(() => {
    // Save form data to local storage whenever it changes
    localStorage.setItem('formData', JSON.stringify(formData));
  }, [formData]);
  
 
 
  const handleRemoveImage = (index) => {
    const newImages = formData.imageURLs.filter((image, i) => {
      if (image && i !== index) {
        return true;
      }
      return false;
    });
    setFormData({...formData, imageURLs: newImages});
    
  };

  const uploadfilechange = (info) => {
    console.log(info);
    const groupSize = info.count;
    const baseCdnUrl = info.cdnUrl;
  
    if (!baseCdnUrl) {
      setImageUploadError("CDN URL not found for the uploaded images");
      return;
    }
  
    if (formData.imageURLs.length + groupSize > 7) {
      setImageUploadError("Only 7 images per listing can be uploaded");
      return;
    }
  
    const cdnUrls = Array.from({length: groupSize}, (_, i) => `${baseCdnUrl}nth/${i}/-/format/auto/-/quality/smart/-/progressive/yes/`);
    setFormData({ ...formData, imageURLs: [...formData.imageURLs, ...cdnUrls] });
  };

  const filesizelimit=()=>{
    return (fileInfo) => {
      if (fileInfo.size > 10000000) {
        alert('Max file size is 10MB');
        return false;
      }
      return true;
    };
  }
  const validators =[filesizelimit()];
  return (
    <>
    <NavBar />
    <Confetti active={showConfetti}/>
    <div>
    {isLoading ? (
       <div className="flex items-center justify-center h-screen">
       <Spinner color="purple" size="xl" />
     </div>
    ):(
    
      <form onSubmit={handleSubmit} className="grid grid-cols-1 sm:grid-cols-2 gap-6 bg-gray-100 p-6 rounded-lg shadow-lg mb-10">
    
      <div>
      <label htmlFor="location" className="block text-sm font-medium text-gray-700">Location</label>
      <input
        type="text"
        id="location"
        name="location"
        value={formData.location}
        onChange={handleInputChange}
        className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
        required
      />
    </div>

      <div>
      <label htmlFor="type" className="block text-sm font-medium text-gray-700">Property Type</label>
      <select name="type" onChange={handleInputChange} value={formData.type} required className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500">
        <option value="">Please select</option>
        <option value="house">House</option>
        <option value="farm_house">Farm House</option>
        <option value="land">Land</option>
        <option value="flat">Flat</option>
        <option value="villa">Villa</option>
        <option value="offfice buildings">Offfice Buildings</option>
        <option value="apartments">Apartments</option>
        <option value="mansions">Mansions</option>
        <option value="penthouse">Penthouse</option>
        <option value="living room">Other</option>
      </select>
    </div>
      <div  className="sm:col-span-2">
        <label htmlFor="title" className="block text-sm font-medium text-gray-700">Property Title*</label>
        <input
          type="text"
          id="title"
          name="title"
          value={formData.title}
          onChange={handleInputChange}
          className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
          required
        />
      </div>

      <div className="sm:col-span-2">
        <label htmlFor="description" className="block text-sm font-medium text-gray-700">Property  Description*</label>
        <textarea
          id="description"
          name="description"
          value={formData.description}
          onChange={handleInputChange}
          rows="4"
          className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
          required
        />
      </div>

      <div>
      <label htmlFor="parkingSlots" className="block text-sm font-medium text-gray-700">Number of Parking Slots*</label>
      <input
        type="number"
        id="parkingSlots"
        name="parkingSlots"
        value={formData.parkingSlots}
        onChange={handleInputChange}
        min="0"
        className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
        required
      />
    </div>

      <div>
        <label htmlFor="price" className="block text-sm font-medium text-gray-700">Price*</label>
        <input
          type="text"
          id="price"
          name="price"
          value={formData.price}
          onChange={handleInputChange}
          className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
          required
        />
      </div>
      <div className="sm:col-span-2">
        <label htmlFor="phoneNumber" className="block text-sm font-medium text-gray-700">Phone Number</label>
         <input
            type="tel"
            id="phoneNumber"
            name="phoneNumber"
            value={formData.phoneNumber}
            onChange={handleInputChange}
            pattern="[0-9]{0,10}"
            maxLength="10"
            className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
            required
          />
      </div>
      <div>
    <label htmlFor="bedrooms" className="block text-sm font-medium text-gray-700">Bedrooms</label>
    <input
      type="number"
      id="bedrooms"
      name="bedrooms"
      value={formData.bedrooms}
      onChange={handleInputChange}
      min="0"
      required
    />
  </div>
  <div>
    <label htmlFor="bathrooms" className="block text-sm font-medium text-gray-700">Bathrooms</label>
    <input
      type="number"
      id="bathrooms"
      name="bathrooms"
      value={formData.bathrooms}
      onChange={handleInputChange}
      min="0"
      required
    />
  </div>
      

     <div className="mt-6">
  <label htmlFor="images" className="block text-gray-700 text-sm font-bold mb-2">Property Images</label>
  <div className="mb-6 py-2  rounded-md focus:outline-none focus:ring focus:ring-blue-400">
    <Widget 
      publicKey="e654302b7d7dad64025e"
      clearable
      multiple
      onChange={(info)=> uploadfilechange(info)}
      validators={validators}
      
    />
  </div>
  {imageUploadError && <p className="text-red-500">{imageUploadError}</p>}
  <div className="flex flex-wrap gap-4">
    {formData.imageURLs.length > 0 && formData.imageURLs.map((url, index) => (
      <div key={url} className="relative">
    <img src={url} alt="property" className="w-32 h-32 object-cover rounded-md" />
    <button type="button" onClick={() => handleRemoveImage(index)} className="absolute top-0 right-0 bg-red-500 text-white rounded-full p-2 -m-2 hover:bg-red-600 focus:outline-none focus:ring focus:ring-red-400">
      <FontAwesomeIcon icon={faTrash}  size="lg"/> {/* Bin icon */}
    </button>
  </div>
    ))}
  </div>
</div>
     <div className="mb-6 mt-11">
  {tourData && tourData.length > 0 ? 
    <FontAwesomeIcon icon={faVrCardboard} size='2x'/>: (
    <div className="mb-2">
      <button
        onClick={() => navigate('/VirtualTour')}
        className="block w-full bg-purple-600 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-md focus:outline-none focus:ring focus:ring-blue-400"
      >
        Build VR Tour
      </button>
    </div>
  )}
  <button
    type="submit"
    disabled={uploadingImages}
    className={`block w-full p-3 bg-purple-600 text-white rounded-lg uppercase hover:bg-purple-700 focus:outline-none focus:ring focus:ring-purple-400 ${
      uploadingImages ? 'opacity-80 cursor-not-allowed' : 'hover:opacity-95'
    }`}
  >
    {uploadingImages ? 'Submitting...' : 'Submit'}
  </button>
</div>
<ToastContainer />
   
     </form>
     
    )}
    </div>
     </>
  )}

export default UploadListing;