import React from 'react'
import image1 from './images/r-architecture-2gDwlIim3Uw-unsplash.jpeg'
import Navbar from './NavBar'
import Footer from './Footer'
import { ContactUs } from './Contact';
import dihas from './images/dihas.webp'
import saadik from './images/saadik.webp'
import samly from './images/samly.webp'
import sasmitha from './images/sasmitha.webp'
import chathuka from './images/chathuka.webp'

const AboutUs = () => {
const TeamMember = ({ name, imageSrc }) => {
    return(
        <div className="flex flex-col items-center">
            <img src={imageSrc} alt={name} className="w-40 h-40 rounded-full object-cover mb-4" />
            <p className="text-lg font-semibold">{name}</p>
        </div>
    )
}

  return (
    <div>
      <Navbar />
      <img src={image1} alt='About Us' className='w-full object-cover h-96 bg-gradient-to-b from-transparent to-black' />
      <div className='py-20 mt-8 px-4 max-w-6xl mx-auto flex flex-wrap items-center'>
        <div className='w-full lg:w-1/2'>
          <h1 className='text-6xl font-bold mb-4 text-purple-700'>About PropVRty</h1>
        </div>
        <div className='w-full lg:w-1/2'>
          <p className='text-lg leading-relaxed text-justify'>Welcome to Propvrty, your premier destination for real estate exploration and connection. At Propvrty, we understand the importance of finding the perfect property, whether it's your dream home or an investment opportunity. That's why we've created a seamless marketplace that brings buyers and sellers together in one convenient platform. With our innovative VR tour feature, you can immerse yourself in a property like never before, allowing you to explore every detail and envision your future. Our mission is to empower you with the tools and resources you need to make informed decisions and find the property that truly feels like home. Join us at Propvrty and embark on your journey towards finding your dream property.</p>
        </div>
        <div className='w-full mt-12 text-center p-6 border-t'>
            <h2 className='text-2xl font-semibold mb-4'>Meet Our Team</h2>
        </div>
        <div className='w-full mt-12'>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-8">
            <TeamMember name="Chathuka" imageSrc={chathuka} />
            <TeamMember name="Dihas" imageSrc={dihas} />
            <TeamMember name="Sasmitha" imageSrc={sasmitha} />
            <TeamMember name="Saadik" imageSrc={saadik} />
            <TeamMember name="Samly" imageSrc={samly} />
          </div>
        </div>
      </div>
      <div className='border-t'>
        <ContactUs/>
      </div>
      
      <Footer />
    </div>
  )
}

export default AboutUs