import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { faHouse } from '@fortawesome/free-solid-svg-icons';
import { faBed } from '@fortawesome/free-solid-svg-icons'; 
import { Link } from 'react-router-dom';

const Services = () => {
    return (
    <section className="bg-gray-100 py-8">
      
    <div className="container mx-auto text-center px-20">
      <h2 className="mt-10 text-2xl font-bold text-gray-800 mb-10">Our Services</h2>
      <div className="flex flex-wrap -mx-4">
        <div className="w-full md:w-1/3 px-4 mb-8">
          
        <Link to ="/ExploreProperties">
          <div className="bg-white p-8 shadow-md rounded-lg transform transition-transform hover:scale-105"> {/* Updated class here */}
            <FontAwesomeIcon icon={faSearch} aria-hidden="true" className="text-4xl text-purple-500 mb-4" />
            <h3 className="text-xl font-bold text-gray-800 mb-2">Buy  a home</h3>
            <p className="text-gray-600">
              Find your place with an immersive VR tours, photo experience and the most listings, including things you won’t find anywhere else.
            </p>
          </div></Link>
        </div>
        
        
        <div className="w-full md:w-1/3 px-4 mb-8"><Link to ="/UploadListing">
          <div className="bg-white p-8 shadow-md rounded-lg transform transition-transform hover:scale-105"> {/* Updated class here */}
            <FontAwesomeIcon icon={faHouse} className="text-4xl text-purple-500 mb-4" />
            <h3 className="text-xl font-bold text-gray-800 mb-2">Sell a House</h3>
            <p className="text-gray-600">
              No matter what path you take to sell your home, add immersive VR tours and  we can help you navigate a successful sale.
            </p>
          </div></Link>
        </div>
        
        <div className="w-full md:w-1/3 px-4 mb-8">
          <div className="bg-white p-8 shadow-md rounded-lg transform transition-transform hover:scale-105"> {/* Updated class here */}
            <FontAwesomeIcon icon={faBed} className="text-4xl text-purple-500 mb-4" />
            <h3 className="text-xl font-bold text-gray-800 mb-2">Rent a Home</h3>
            <p className="text-gray-600">
              We’re creating a seamless online experience – from shopping on the largest rental network, to applying, to paying rent.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
  
    );
  };

export default Services;