import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import app from './firebase'; 
import NavBar from './NavBar';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Widget } from "@uploadcare/react-widget";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { Spinner } from 'flowbite-react';


const EditListing = () => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [formData, setFormData] = useState({
    location: "",
    type: "",
    title: "",
    description: "",
    parkingSlots: 0,
    price: 0,
    phoneNumber: "",
    bedrooms: 0,
    bathrooms: 0,
    imageURLs: [],
  });
  const [loading, setLoading] = useState(true);
  const [uploadingImages, setUploadingImages] = useState(false); // State to track image upload progress

  useEffect(() => {
    const fetchListing = async () => {
      try {
        const response = await axios.get(`https://propvrty.onrender.com/api/listings/${id}`);
        const data = response.data;
        setIsLoading(false);
        setFormData(data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching listing:", error); 
      }
    };

    fetchListing();
  }, [id]);

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  
  

  const handleRemoveImage = (index) => {
    const updatedImages = [...formData.imageURLs];
    updatedImages.splice(index, 1);
    setFormData({ ...formData, imageURLs: updatedImages });
  };

  let navigate = useNavigate();

  const handleSubmit = async (e) => {
  e.preventDefault();
 

  // Validation checks
  if (
    formData.location.trim() === "" ||
    formData.type.trim() === "" ||
    formData.title.trim() === "" ||
    formData.description.trim() === "" ||
    formData.parkingSlots <= 0 ||
    formData.bedrooms <= 0 ||
    formData.bathrooms <= 0 ||
    formData.phoneNumber.trim().length !== 10 ||
    isNaN(parseInt(formData.phoneNumber.trim()))
  ) {
    alert("Please fill in all fields correctly.");
    return;
  }

   if (formData.imageURLs.length === 0) {
    toast.error('At least one image must be uploaded.');
    return;
  }

  if (formData.imageURLs.length > 7) {
  toast.error('No more than 7 images can be uploaded.');
  return;
}


  try {
    const response = await axios.put(`https://propvrty.onrender.com/api/listings/${id}`, formData);
    if (response.status === 200 || response.status === 201) {
      console.log("Listing updated:", response.data);
      alert("Listing updated successfully");
      navigate("/profile");
    } else {
      console.log("Error updating listing:", response.status, response.statusText);
      alert("Error updating listing:", response.status, response.statusText);
    }
  } catch (error) {
    console.error("Error updating listing:", error);
    alert("Error updating listing:", error);
  }
};

 
  const filesizelimit=()=>{
    return (fileInfo) => {
      if (fileInfo.size > 10000000) {
        alert('Max file size is 10MB');
        return false;
      }
      return true;
    };
  }

  const uploadfilechange = (info) => {
    console.log(info);
    const groupSize = info.count;
    const baseCdnUrl = info.cdnUrl;
  
    if (!baseCdnUrl) {
      setLoading("CDN URL not found for the uploaded images");
      return;
    }
  
    if (formData.imageURLs.length + groupSize > 7) {
      setLoading("Only 7 images per listing can be uploaded");
      return;
    }
  
    const cdnUrls = Array.from({length: groupSize}, (_, i) => `${baseCdnUrl}nth/${i}/-/format/auto/-/quality/smart/-/progressive/yes/`);
    setFormData({ ...formData, imageURLs: [...formData.imageURLs, ...cdnUrls] });
  };

const validators =[filesizelimit()];
  return (
    <>
    <NavBar />

    {isLoading ? (
      <div className="flex items-center justify-center h-screen">
      <Spinner color="purple" size="xl" />
    </div>
    ):(
      <div>
          <form onSubmit={handleSubmit} className="grid grid-cols-1 sm:grid-cols-2 gap-6 bg-gray-100 p-6 rounded-lg shadow-lg">
      <div>
      <label htmlFor="location" className="block text-sm font-medium text-gray-700">Location</label>
      <input
        type="text"
        id="location"
        name="location"
        value={formData.location}
        onChange={handleInputChange}
        className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
        required
      />
    </div>

      <div>
      <label htmlFor="type" className="block text-sm font-medium text-gray-700">Property Type</label>
      <select name="type" onChange={handleInputChange} value={formData.type} required className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500">
        <option value="">Please select</option>
        <option value="house">House</option>
        <option value="farm_house">Farm House</option>
        <option value="land">Land</option>
        <option value="flat">Flat</option>
        <option value="villa">Villa</option>
        <option value="offfice buildings">Offfice Buildings</option>
        <option value="apartments">Apartments</option>
        <option value="mansions">Mansions</option>
        <option value="penthouse">Penthouse</option>
        <option value="living room">Other</option>
      </select>
    </div>
      <div  className="sm:col-span-2">
        <label htmlFor="title" className="block text-sm font-medium text-gray-700">Property Title*</label>
        <input
          type="text"
          id="title"
          name="title"
          value={formData.title}
          onChange={handleInputChange}
          className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
          required
        />
      </div>

      <div className="sm:col-span-2">
        <label htmlFor="description" className="block text-sm font-medium text-gray-700">Property  Description*</label>
        <textarea
          id="description"
          name="description"
          value={formData.description}
          onChange={handleInputChange}
          rows="4"
          className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
          required
        />
      </div>

      <div>
      <label htmlFor="parkingSlots" className="block text-sm font-medium text-gray-700">Number of Parking Slots*</label>
      <input
        type="number"
        id="parkingSlots"
        name="parkingSlots"
        value={formData.parkingSlots}
        onChange={handleInputChange}
        min="0"
        className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
        required
      />
    </div>

      <div>
        <label htmlFor="price" className="block text-sm font-medium text-gray-700">Price*</label>
        <input
          type="text"
          id="price"
          name="price"
          value={formData.price}
          onChange={handleInputChange}
          className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
          required
        />
      </div>
      <div className="sm:col-span-2">
        <label htmlFor="phoneNumber" className="block text-sm font-medium text-gray-700">Phone Number</label>
         <input
            type="tel"
            id="phoneNumber"
            name="phoneNumber"
            value={formData.phoneNumber}
            onChange={handleInputChange}
            pattern="[0-9]{0,10}"
            maxLength="10"
            className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
            required
          />
      </div>
      <div>
    <label htmlFor="bedrooms" className="block text-sm font-medium text-gray-700">Bedrooms</label>
    <input
      type="number"
      id="bedrooms"
      name="bedrooms"
      value={formData.bedrooms}
      onChange={handleInputChange}
      min="0"
      required
    />
  </div>
  <div>
    <label htmlFor="bathrooms" className="block text-sm font-medium text-gray-700">Bathrooms</label>
    <input
      type="number"
      id="bathrooms"
      name="bathrooms"
      value={formData.bathrooms}
      onChange={handleInputChange}
      min="0"
      required
    />
  </div>
      

     <div className="mt-6">
  <label htmlFor="images" className="block text-gray-700 text-sm font-bold mb-2">Property Images</label>
   <div className="mb-6 py-2  rounded-md focus:outline-none focus:ring focus:ring-blue-400">
    <Widget 
      publicKey="e654302b7d7dad64025e"
      clearable
      multiple
      onChange={(info)=> uploadfilechange(info)}
      validators={validators}
      
    />
  </div>
  {loading && <p className="text-red-500">{loading}</p>}
  <div className="flex flex-wrap gap-4">
    {formData.imageURLs.length > 0 && formData.imageURLs.map((url, index) => (
      <div key={url} className="relative">
    <img src={url} alt="property" className="w-32 h-32 object-cover rounded-md" />
    <button type="button" onClick={() => handleRemoveImage(index)} className="absolute top-0 right-0 bg-red-500 text-white rounded-full p-2 -m-2 hover:bg-red-600 focus:outline-none focus:ring focus:ring-red-400">
      <FontAwesomeIcon icon={faTrash} /> {/* Bin icon */}
    </button>
  </div>
    ))}
  </div>
</div>
     
  <div>
  <button
  type="submit"
  disabled={uploadingImages}
  className={`mt-16 block w-full py-1 px-3 bg-purple-600 text-white rounded-lg uppercase hover:bg-purple-700 focus:outline-none focus:ring focus:ring-purple-400 ${
    uploadingImages ? 'opacity-80 cursor-not-allowed' : 'hover:opacity-95'
  }`}
>
  {uploadingImages ? 'Submitting...' : 'Submit'}
</button>
  </div>


<ToastContainer />
   
     </form>   
      </div>
    )}
     </>
  )}

export default EditListing;