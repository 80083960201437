import React from "react";
import heroimg from "./images/hero.webp";
import{Link} from 'react-router-dom';

const Hero = () => {
  const bgImage = {
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    height: "100%",
    width: "100%",
  };

  

  return (
    <>
      {/* <div
        className="min-h-[550px] sm:min-h-[600px] bg-white flex justify-center items-center dark:bg-gray-950 dark:text-white duration-200"
        style={bgImage}
      > */}
      <div
        className="min-h-[550px] sm:min-h-[600px] bg-white flex justify-center items-center dark:bg-white:text-black duration-200"
        style={bgImage}
      >
        <div className="container pb-8 sm:pb-0">
          <div className="grid grid-cols-1 sm:grid-cols-2">
            {/* text content section */}
            <div
              data-aos="zoom-out"
              data-aos-duration="400"
              data-aos-once="true"
              className="flex flex-col justify-center gap-4 pt-12 pl-25 sm:pt-0 text-center sm:text-left order-2 sm:order-1"
            >
              <h1 style={{ fontSize: '48px' }} className="sm:text-5xl lg:text-6xl font-bold">
                Welcome To 
                <div class="whitespace-pre-line text-lg font-semibold"></div>
                PropVRty
              </h1>
              <p className="text-24 font-bold">
                Unlock Your Dream Home: Seamless Connections, 
                <div class="whitespace-pre-line text-lg font-semibold"></div>
                Virtual Tours, and Effortless Deals
              </p>
              <div>
                <Link to="/UploadListing">
                <button className="bg-black text-white py-2 px-4 rounded mr-4">
                List Properties
                </button>
                </Link>
                <Link to="/ExploreProperties">
                <button className="bg-black text-white py-2 px-4 rounded">
                Explore Properties
                </button>
                </Link>
              </div>
            </div>
            {/* Image section */}
            <div className="min-h-[600px] sm:min-h-[450px] flex justify-center items-center relative order-1 sm:order-2 ">
              <div className="h-[600px] w-[900px] sm:h-[450px] overflow-hidden flex justify-center items-center">
                <img
                  data-aos="zoom-in"
                  data-aos-duration="300"
                  data-aos-once="true"
                  alt="home"
                  src={heroimg}
                  className=" sm:w-[450px] sm:h-[450px] object-cover sm:object-cover sm:scale-125 mx-auto spin"
                  
                />
              </div>
              <div className="flex lg:flex-col lg:top-1/2 lg:-translate-y-1/2 lg:py-2 justify-center gap-4 absolute bottom-[0px] lg:-right-10 bg-white/30 rounded-full">
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;