import React from 'react'
import { SocialIcon } from 'react-social-icons'
import './footer.css'

export const Footer = () => {
    return (
        <>
        <footer className="footer">
            <div className='footer-top'>
                <div className="footer-top-left">
                    <div className="footer-top-text">
                        <p>Follow Us</p>
                    </div>
                    <div className="footer-icons">
                    <SocialIcon url="https://github.com/dihass/PropVRty" network="github"  className='icon'/>
                    <SocialIcon url="https://twitter.com/propvrty" network="twitter" className='icon' />
                    <SocialIcon url="https://facebook.com/propvrty" network="facebook" className='icon'/>
                    <SocialIcon url="https://www.linkedin.com/in/propvrty-undefined-31b4a7300/" network="linkedin" className='icon'/>
                    </div>
                </div>
                <div className='footer-top-right'>
                     <div className="footer-top-text">
                        <p>Subscribe</p>
                    </div>
                    <div className='footer-input'>
                        <input type="text" placeholder="Enter your email" className='input'/>
                        <button className='subscribe-button'>Subscribe</button>
                    </div>    
                </div>
            </div>
            <div className='footer-bottom'>
                <div className='footer-bottom-top'>
                    <p>Privacy Policy</p>
                    <p>Terms of Service</p>
                    <p>Sales and Refunds</p>
                    <p>Legal</p>
                </div>
                <div className='footer-bottom-'>
                    <p>© 2024 Real Estate</p>
                </div>
            </div>
        </footer>
        </>
    )
}
export default Footer;
