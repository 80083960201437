import React from 'react'
import NavBar from './NavBar'
import PropertiesList from './PropertiesList'
import Footer from './Footer'
import '../home.css'



const ExploreProperties = () => {
  return (
    <>  
    <NavBar />
    <PropertiesList />
    <Footer />
    </>
    
  )
}


export default ExploreProperties;